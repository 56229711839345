import { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { Stock } from '../../interfaces/Stock';
import { getStockHistory } from '../../services/Stock';
import { sharedColors, sharedStyles } from '../../utils/Style';
import { chartAxisLabelFormatter } from '../../utils/Chart';
import Waiting from '../Waiting';
import { applicableHistoryPeriods } from '../../variables/HistoryPeriod';
import { HistoryPeriod } from '../../interfaces/HistoryPeriod';
import {
  getTextFromHistoryPeriod,
  getWorkdayCountFromPeriod,
} from '../../utils/HistoryPeriod';

interface StockHistoryDialogProps {
  symbol?: string;
  onClose: () => void;
}

const StockHistoryDialog = (props: StockHistoryDialogProps) => {
  const [loading, setLoading] = useState(false);
  const [stockHistory, setStockHistory] = useState<Stock[]>([]);
  const [historyPeriod, setHistoryPeriod] = useState(
    applicableHistoryPeriods[0],
  );

  useEffect(() => {
    if (props.symbol) {
      setLoading(true);
      getStockHistory(props.symbol)
        .then((fetchedHistory) => setStockHistory(fetchedHistory))
        .catch((err) => toast.error(err.message))
        .finally(() => setLoading(false));
    } else {
      setStockHistory([]);
    }
  }, [props.symbol]);

  const handleChangeHistoryPeriod = (
    _: any,
    newPeriod: HistoryPeriod | null,
  ) => {
    if (!newPeriod) {
      return;
    }

    setHistoryPeriod(newPeriod);
  };

  const slicedStockHistory = stockHistory.slice(
    Math.max(0, stockHistory.length - getWorkdayCountFromPeriod(historyPeriod)),
  );

  const options: ApexOptions = {
    chart: {
      type: 'line',
      zoom: {
        enabled: false,
      },
      animations: { enabled: false },
    },
    title: {
      text: props.symbol ?? '',
      align: 'left',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    fill: {
      type: 'solid',
      colors: ['transparent'],
    },
    grid: {
      row: {
        colors: [sharedColors.gray2, 'transparent'],
        opacity: 0.5,
      },
    },
    yaxis: [
      {
        labels: {
          formatter: chartAxisLabelFormatter,
        },
      },
    ],
    xaxis: {
      categories: slicedStockHistory.map((entry) => entry.day),
      labels: {
        show: false,
      },
    },
  };

  const series: ApexAxisChartSeries = [
    {
      name: 'Değer',
      data: slicedStockHistory.map((entry) => ({
        x: entry.day,
        y: entry.close,
      })),
    },
    {
      name: 'Düşük',
      data: slicedStockHistory.map((entry) => ({
        x: entry.day,
        y: entry.low,
      })),
      color: sharedColors.statusRed,
    },
    {
      name: 'Yüksek',
      data: slicedStockHistory.map((entry) => ({
        x: entry.day,
        y: entry.high,
      })),
      color: sharedColors.statusGreen,
    },
  ];

  return (
    <Dialog
      open={!!props.symbol}
      maxWidth={false}
      PaperProps={{
        style: {
          width: 800,
        },
      }}
      onClose={props.onClose}
    >
      <Waiting open={loading} />
      <DialogTitle sx={{ ...sharedStyles.h6, color: sharedColors.gray6 }}>
        Hisse Geçmişi
      </DialogTitle>
      <DialogContent>
        <Box component='div'>
          <Chart
            options={options}
            series={series}
            type='line'
            width='100%'
            height={500}
          />
        </Box>
        <Box
          component='div'
          sx={{
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <ToggleButtonGroup
            color='primary'
            value={historyPeriod}
            exclusive
            onChange={handleChangeHistoryPeriod}
          >
            {applicableHistoryPeriods.map((period) => (
              <ToggleButton value={period} sx={sharedStyles.buttonText}>
                {getTextFromHistoryPeriod(period)}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 2.5, pb: 1.5 }}>
        <Button
          variant='contained'
          color='inherit'
          onClick={props.onClose}
          sx={sharedStyles.buttonText}
        >
          Kapat
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StockHistoryDialog;
