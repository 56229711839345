import { isArray } from 'lodash';
import { QueryParams, QueryType } from '../interfaces/Query';
import { Stock, stockFromResponse } from '../interfaces/Stock';
import api from './Api';

const queryBaseUrl = '/query/';

export const queryStocks = async (
  queryType: QueryType,
  queryParams: QueryParams,
): Promise<Stock[]> => {
  const response = await api.post(queryBaseUrl, {
    query_type: queryType,
    query_params: queryParams,
  });

  if (!isArray(response.data)) {
    throw new Error('Unexpected response: Response data is not an array');
  }

  return response.data.map((stock) => stockFromResponse(stock));
};
