export interface TokenData {
  username: string;
  email: string;
  expiresAt: Date;
}

export const tokenDataFromObject = (rawObject: any): TokenData => ({
  username: rawObject.username,
  email: rawObject.email,
  expiresAt: new Date(rawObject.exp * 1000),
});
