export interface Target {
  targetID: number;
  symbol: string;
  targetValue: number;
  isGreater: boolean;
  currentValue: number;
  isSatisfied: boolean;
  email: string;
  targetLastUpdatedAt?: Date;
  stockLastUpdatedAt?: Date;
}

export const targetFromResponse = (responseData: any): Target => ({
  targetID: responseData.target_id,
  symbol: responseData.symbol,
  targetValue: responseData.target_value,
  isGreater: responseData.is_greater,
  currentValue: responseData.current_value,
  isSatisfied: !!responseData.is_satisfied,
  email: responseData.email,
  targetLastUpdatedAt: responseData.target_last_updated_at
    ? new Date(responseData.target_last_updated_at)
    : undefined,
  stockLastUpdatedAt: responseData.stock_last_updated_at
    ? new Date(responseData.stock_last_updated_at)
    : undefined,
});

export interface TargetToCreate {
  symbol: string;
  value: number;
  isGreater: boolean;
}

export const targetOutputTransformer = (targetToCreate: TargetToCreate) => ({
  symbol: targetToCreate.symbol,
  value: targetToCreate.value,
  is_greater: targetToCreate.isGreater,
});
