import { useSetRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import { Box } from '@mui/material';
import { selectedPageAtom } from '../../atoms/Page';
import { Target } from '../../interfaces/Target';
import { getTargets } from '../../services/Target';
import { PageType } from '../../interfaces/Page';
import Waiting from '../Waiting';
import { sharedStyles } from '../../utils/Style';
import TargetsTable from './TargetsTable';
import { Stock } from '../../interfaces/Stock';
import { getStocks } from '../../services/Stock';

const TargetsPage = () => {
  const setSelectedPage = useSetRecoilState(selectedPageAtom);

  const [loadingTargets, setLoadingTargets] = useState(false);
  const [loadingStocks, setLoadingStocks] = useState(false);
  const [targets, setTargets] = useState<Target[]>([]);
  const [stocks, setStocks] = useState<Stock[]>([]);

  const refreshTargets = () => {
    setLoadingTargets(true);
    getTargets()
      .then((fetchedTargets) => setTargets(fetchedTargets))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingTargets(false));
  };

  useEffect(() => {
    setSelectedPage(PageType.TARGETS);
    refreshTargets();

    setLoadingStocks(true);
    getStocks()
      .then((fetchedStocks) => setStocks(fetchedStocks))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoadingStocks(false));
  }, []);

  const loading = loadingTargets || loadingStocks;

  return (
    <Box component='div'>
      <Waiting open={loading} />
      <Box component='div' sx={sharedStyles.pageBody}>
        <TargetsTable
          targets={targets}
          stocks={stocks}
          onChange={refreshTargets}
        />
      </Box>
    </Box>
  );
};

export default TargetsPage;
