import { QueryType } from '../interfaces/Query';

export const applicableQueryTypes: QueryType[] = [
  QueryType.CONSTANTLY_FALLING,
  QueryType.CONSTANTLY_RISING,
  QueryType.PERCENT_FALLING,
  QueryType.PERCENT_RISING,
  QueryType.MINIMUM_VALUES,
  QueryType.RSI,
];
