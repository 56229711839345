import React, { JSX, useEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Popover,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AppsIcon from '@mui/icons-material/Apps';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { appBarHeightAtom } from '../atoms/AppHeader';
import { selectedPageAtom } from '../atoms/Page';
import { sharedColors, sharedStyles } from '../utils/Style';
import { getTokenData, removeToken } from '../utils/Auth';
import { pages } from '../variables/Page';

const DummyScrollButton = () => <></>;

const AppHeader = () => {
  const navigate = useNavigate();

  const ref = useRef(null);

  const setAppBarHeight = useSetRecoilState(appBarHeightAtom);
  const selectedPage = useRecoilValue(selectedPageAtom);

  const [settingsPopoverAnchor, setSettingsPopoverAnchor] =
    useState<HTMLButtonElement | null>(null);

  const showSettingsPopover = Boolean(settingsPopoverAnchor);
  const settingsPopoverId = showSettingsPopover
    ? 'settings-popover'
    : undefined;

  useEffect(() => {
    if (ref && ref.current) {
      // @ts-ignore
      setAppBarHeight(ref.current.clientHeight);
    }
  });

  const handleNavigate = (to: string) => {
    navigate(to);
  };

  const handleNavigateMainPage = () => {
    handleNavigate(pages[0].path);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    handleNavigate(pages[newValue].path);
  };

  const handleShowSettingsPopover = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setSettingsPopoverAnchor(event.currentTarget);
  };

  const handleCloseSettingsPopover = () => {
    setSettingsPopoverAnchor(null);
  };

  const handleLogout = () => {
    removeToken();
    navigate('/login');
  };

  const generateTabs = (): JSX.Element[] =>
    pages.map((page) => (
      <Tab
        label={
          <Typography
            sx={{
              textTransform: 'none',
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '16px',
              color: sharedColors.gray6,
              display: 'flex',
              alignItems: 'center',
              mb: -0.25,
            }}
          >
            {page.name}
          </Typography>
        }
      />
    ));

  return (
    <Box component='div'>
      <AppBar
        position='fixed'
        elevation={1}
        ref={ref}
        sx={{
          backgroundColor: '#FFFFFF',
          overflowX: 'auto',
        }}
      >
        <Box
          component='div'
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
            paddingRight: 3,
            paddingLeft: 1,
          }}
        >
          <IconButton
            sx={{
              minHeight: 0,
              mt: 'auto',
              mb: 0.5,
            }}
          >
            <AppsIcon />
          </IconButton>
          <Button
            onClick={handleNavigateMainPage}
            color='primary'
            sx={{
              ...sharedStyles.buttonText,
              ...sharedStyles.h6,
              p: 1.5,
              minHeight: 0,
              mt: 'auto',
              mb: 0.5,
              mr: 1,
            }}
          >
            <Box component='img' src='/plaizen.png' width={90} />
          </Button>
          <Box
            component='div'
            sx={{
              display: 'flex',
              flexGrow: 1,
              marginBottom: 0,
              height: '100%',
            }}
          >
            <Tabs
              variant='scrollable'
              value={pages.findIndex((page) => page.pageType === selectedPage)}
              onChange={handleTabChange}
              ScrollButtonComponent={DummyScrollButton}
            >
              {generateTabs()}
            </Tabs>
          </Box>
          <Button
            startIcon={<AccountCircleIcon fontSize='large' />}
            size='large'
            onClick={handleShowSettingsPopover}
            sx={{ ...sharedStyles.buttonText, fontSize: 16 }}
          >
            {getTokenData()?.username ?? 'user'}
          </Button>
          <Popover
            id={settingsPopoverId}
            open={showSettingsPopover}
            anchorEl={settingsPopoverAnchor}
            onClose={handleCloseSettingsPopover}
            disableScrollLock
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box
              component='div'
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <Button
                startIcon={<LogoutIcon />}
                onClick={handleLogout}
                sx={{
                  padding: 1.5,
                  textTransform: 'none',
                  justifyContent: 'flex-start',
                }}
              >
                Oturumu Kapat
              </Button>
            </Box>
          </Popover>
        </Box>
      </AppBar>
    </Box>
  );
};

export default AppHeader;
