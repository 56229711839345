import { useSetRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { isEmpty } from 'lodash';
import { toast } from 'material-react-toastify';
import { selectedPageAtom } from '../../atoms/Page';
import { PageType } from '../../interfaces/Page';
import { sharedColors, sharedStyles } from '../../utils/Style';
import { applicableQueryTypes } from '../../variables/Query';
import { QueryType } from '../../interfaces/Query';
import {
  getDefaultQueryParamsByType,
  getQueryTypeName,
} from '../../utils/Query';
import ConstantlyFallingRisingForm from './Forms/ConstantlyFallingRisingForm';
import PeriodicFallingRisingForm from './Forms/PeriodicFallingRisingForm';
import { Stock } from '../../interfaces/Stock';
import { queryStocks } from '../../services/Query';
import StocksTable from '../Stock/StocksTable';
import Waiting from '../Waiting';
import RsiIndicatorForm from './Forms/RsiIndicatorForm';

const QueriesPage = () => {
  const setSelectedPage = useSetRecoilState(selectedPageAtom);

  const [loading, setLoading] = useState(false);
  const [selectedQueryType, setSelectedQueryType] = useState(
    applicableQueryTypes[0],
  );
  const [queryParams, setQueryParams] = useState(
    getDefaultQueryParamsByType(applicableQueryTypes[0]),
  );
  const [resultingStocks, setResultingStocks] = useState<Stock[]>([]);

  useEffect(() => {
    setSelectedPage(PageType.QUERIES);
  }, []);

  useEffect(() => {
    setQueryParams(getDefaultQueryParamsByType(selectedQueryType));
  }, [selectedQueryType]);

  const getParamsForm = () => {
    switch (selectedQueryType) {
      case QueryType.CONSTANTLY_FALLING:
      case QueryType.CONSTANTLY_RISING:
        return (
          <ConstantlyFallingRisingForm
            queryType={selectedQueryType}
            queryParams={queryParams}
            onChange={setQueryParams}
          />
        );
      case QueryType.PERCENT_FALLING:
      case QueryType.PERCENT_RISING:
        return (
          <PeriodicFallingRisingForm
            queryType={selectedQueryType}
            queryParams={queryParams}
            onChange={setQueryParams}
          />
        );
      case QueryType.RSI:
        return (
          <RsiIndicatorForm
            queryType={selectedQueryType}
            queryParams={queryParams}
            onChange={setQueryParams}
          />
        );
      default:
        return <></>;
    }
  };

  const handleQuery = () => {
    setLoading(true);
    queryStocks(selectedQueryType, queryParams)
      .then((result) => {
        if (isEmpty(result)) {
          toast.warning('Filtrelere uyan bir sonuç bulunamadı');
        }
        setResultingStocks(result);
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  return (
    <Box component='div' sx={sharedStyles.pageBody}>
      <Waiting open={loading} />
      <Typography
        sx={{
          ...sharedStyles.h5,
          color: sharedColors.black,
          fontWeight: 450,
        }}
      >
        Sorgu Seçenekleri
      </Typography>
      <FormControl
        size='small'
        sx={{ width: 400, backgroundColor: sharedColors.white, mt: 1.5 }}
      >
        <InputLabel>Sorgu tipi</InputLabel>
        <Select
          value={selectedQueryType}
          label='Sorgu tipi'
          onChange={(e) => setSelectedQueryType(e.target.value as QueryType)}
          name='select'
          required
        >
          {applicableQueryTypes.map((queryType) => (
            <MenuItem value={queryType} key={queryType}>
              {getQueryTypeName(queryType)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {getParamsForm()}
      <Button
        variant='contained'
        color='primary'
        startIcon={<SearchIcon />}
        onClick={handleQuery}
        sx={{
          mt: 1.5,
          mb: 2.5,
          ...sharedStyles.buttonText,
          width: 'fit-content',
        }}
      >
        Sorgula
      </Button>
      {!isEmpty(resultingStocks) && (
        <StocksTable title='Sonuç' stocks={resultingStocks} />
      )}
    </Box>
  );
};

export default QueriesPage;
