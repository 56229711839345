import { TokenData, tokenDataFromObject } from '../interfaces/TokenData';

export const getStoredToken = (): string | null =>
  localStorage.getItem('token');

export const storeToken = (token: string) =>
  localStorage.setItem('token', token);

export const removeToken = () => localStorage.removeItem('token');

export const getTokenData = (): TokenData | null => {
  const jwt = getStoredToken();
  if (!jwt) {
    return null;
  }

  const parts = jwt.split('.');
  if (parts.length < 2) {
    return null;
  }

  const base64Url = parts[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const payloadJson = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );

  const rawPayload = JSON.parse(payloadJson);
  return tokenDataFromObject(rawPayload);
};

export const isTokenValid = (): boolean => {
  const tokenData = getTokenData();
  return tokenData
    ? tokenData.expiresAt.getTime() > new Date().getTime()
    : false;
};
