import { Box } from '@mui/material';
import { QueryFormProps } from '../../../interfaces/Query';
import NumberInput from '../../NumberInput';
import { sharedColors } from '../../../utils/Style';

const ConstantlyFallingRisingForm = (props: QueryFormProps) => {
  return (
    <Box
      component='div'
      sx={{ mt: 1.5, backgroundColor: sharedColors.white, width: 150 }}
    >
      <NumberInput
        label='Gün'
        value={props.queryParams.days}
        onChange={(newDays) =>
          props.onChange({ ...props.queryParams, days: newDays })
        }
        error={!props.queryParams.days || props.queryParams.days < 0}
      />
    </Box>
  );
};

export default ConstantlyFallingRisingForm;
