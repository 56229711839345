import { LoginResult, loginResultFromResponse } from '../interfaces/User';
import api from './Api';

const userBaseUrl = '/user/';

export const login = async (
  username: string,
  password: string,
): Promise<LoginResult> => {
  const response = await api.post(`${userBaseUrl}login`, {
    username,
    password,
  });

  if (!response.data) {
    throw new Error('Could not login: empty response data');
  }

  return loginResultFromResponse(response.data);
};
