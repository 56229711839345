import { useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Stock } from '../../interfaces/Stock';
import { Pagination } from '../../interfaces/Pagination';
import { sharedColors, sharedStyles } from '../../utils/Style';
import { formatLocalTime } from '../../utils/Date';
import StockHistoryDialog from './StockHistoryDialog';

interface StocksTableProps {
  title?: string;
  stocks: Stock[];
}

const StocksTable = (props: StocksTableProps) => {
  const [pagination, setPagination] = useState<Pagination>({
    pageNumber: 0,
    pageSize: 15,
  });
  const [searchedValue, setSearchedValue] = useState('');
  const [clickedSymbol, setClickedSymbol] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    setPagination({
      ...pagination,
      pageNumber: 0,
    });
  }, [searchedValue, props.stocks]);

  const handleSearch = (e: any) => {
    setSearchedValue(e.target.value ?? '');
  };

  let filteredRows = props.stocks.filter((stock) =>
    stock.symbol.toUpperCase().includes(searchedValue.toUpperCase()),
  );

  filteredRows = filteredRows.sort((s1, s2) =>
    s1.symbol.localeCompare(s2.symbol),
  );

  const paginatedRows = filteredRows.slice(
    pagination.pageNumber * pagination.pageSize,
    (pagination.pageNumber + 1) * pagination.pageSize,
  );

  const extraColumns = Object.keys(props.stocks[0]?.metadata ?? {});

  return (
    <Box component='div' sx={sharedStyles.outerTableContainer}>
      <StockHistoryDialog
        symbol={clickedSymbol}
        onClose={() => setClickedSymbol(undefined)}
      />
      <Typography
        sx={{
          ...sharedStyles.h5,
          color: sharedColors.black,
          fontWeight: 450,
        }}
      >
        {props.title ?? 'Güncel Değerler'}
      </Typography>
      <Box
        component='div'
        sx={{ display: 'flex', flexGrow: 1, flexDirection: 'row', mt: 1.5 }}
      >
        <TextField
          id='search-text-field'
          size='small'
          label='Ara'
          variant='outlined'
          value={searchedValue}
          onChange={handleSearch}
          sx={{ width: 230, backgroundColor: sharedColors.white, mr: 1.5 }}
        />
      </Box>
      <TableContainer component={Paper} sx={sharedStyles.tableContainer}>
        <Table size='small'>
          <TableHead sx={{ backgroundColor: sharedColors.gray2 }}>
            <TableRow>
              <TableCell>
                <Typography sx={sharedStyles.columnLabel}>Sembol</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={sharedStyles.columnLabel}>Son Değer</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={sharedStyles.columnLabel}>Yüksek</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={sharedStyles.columnLabel}>Düşük</Typography>
              </TableCell>
              {extraColumns.map((column) => (
                <TableCell>
                  <Typography sx={sharedStyles.columnLabel}>
                    {column}
                  </Typography>
                </TableCell>
              ))}
              <TableCell>
                <Typography sx={sharedStyles.columnLabel}>
                  Son Güncellenme
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.map((stock) => (
              <TableRow
                key={stock.symbol}
                onClick={() => setClickedSymbol(stock.symbol)}
                sx={sharedStyles.editableRow}
              >
                <TableCell>
                  <Typography sx={sharedStyles.tableStringField}>
                    {stock.symbol}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={sharedStyles.tableStringField}>
                    {stock.close.toFixed(2)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={sharedStyles.tableStringField}>
                    {stock.high.toFixed(2)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={sharedStyles.tableStringField}>
                    {stock.low.toFixed(2)}
                  </Typography>
                </TableCell>
                {extraColumns.map((column) => (
                  <TableCell>
                    <Typography sx={sharedStyles.tableStringField}>
                      {stock.metadata[column] ?? ''}
                    </Typography>
                  </TableCell>
                ))}
                <TableCell>
                  <Typography sx={sharedStyles.tableStringField}>
                    {stock.lastUpdatedAt
                      ? formatLocalTime(stock.lastUpdatedAt)
                      : '-'}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          count={filteredRows.length}
          page={pagination.pageNumber}
          onPageChange={(_, newPage) =>
            setPagination({ ...pagination, pageNumber: newPage })
          }
          rowsPerPage={pagination.pageSize}
          onRowsPerPageChange={(e) =>
            setPagination({
              pageNumber: 0,
              pageSize: parseInt(e.target.value, 10),
            })
          }
          rowsPerPageOptions={[5, 10, 15, 25, 50]}
          component='div'
          labelRowsPerPage='Sayfa başına satır:'
        />
      </TableContainer>
    </Box>
  );
};

export default StocksTable;
