import { Page, PageType } from '../interfaces/Page';
import StocksPage from '../components/Stock/StocksPage';
import TargetsPage from '../components/Target/TargetsPage';
import QueriesPage from '../components/Query/QueriesPage';

export const pages: Page[] = [
  {
    pageType: PageType.STOCKS,
    name: 'Hisseler',
    path: '/stocks',
    disabled: () => false,
    component: <StocksPage />,
  },
  {
    pageType: PageType.TARGETS,
    name: 'Hedefler',
    path: '/targets',
    disabled: () => false,
    component: <TargetsPage />,
  },
  {
    pageType: PageType.QUERIES,
    name: 'Sorgular',
    path: '/queries',
    disabled: () => false,
    component: <QueriesPage />,
  },
];
