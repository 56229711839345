export enum PageType {
  STOCKS = 'STOCKS',
  TARGETS = 'TARGETS',
  QUERIES = 'QUERIES',
  LOGIN = 'LOGIN',
}

export interface Page {
  pageType: PageType;
  name: string;
  path: string;
  disabled: () => boolean;
  component: JSX.Element;
}
