import { Box } from '@mui/material';
import { QueryFormProps } from '../../../interfaces/Query';
import { sharedColors } from '../../../utils/Style';
import NumberInput from '../../NumberInput';

const PeriodicFallingRisingForm = (props: QueryFormProps) => {
  return (
    <Box
      component='div'
      sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, mt: 1.5 }}
    >
      <Box
        component='div'
        sx={{ mr: 1.5, width: 150, backgroundColor: sharedColors.white }}
      >
        <NumberInput
          label='Gün'
          value={props.queryParams.days}
          onChange={(newDays) =>
            props.onChange({ ...props.queryParams, days: newDays })
          }
          error={!props.queryParams.days || props.queryParams.days < 0}
        />
      </Box>
      <Box
        component='div'
        sx={{ mr: 1.5, width: 150, backgroundColor: sharedColors.white }}
      >
        <NumberInput
          label='Yüzde'
          value={props.queryParams.percentage}
          onChange={(newPercentage) =>
            props.onChange({ ...props.queryParams, percentage: newPercentage })
          }
          error={
            !props.queryParams.percentage || props.queryParams.percentage < 0
          }
        />
      </Box>
    </Box>
  );
};

export default PeriodicFallingRisingForm;
