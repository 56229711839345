import { isArray } from 'lodash';
import {
  Target,
  targetFromResponse,
  targetOutputTransformer,
  TargetToCreate,
} from '../interfaces/Target';
import api from './Api';

const targetBaseUrl = '/target/';

export const createTarget = async (
  targetToCreate: TargetToCreate,
): Promise<void> =>
  api.post(targetBaseUrl, targetOutputTransformer(targetToCreate));

export const getTargets = async (): Promise<Target[]> => {
  const response = await api.get(targetBaseUrl);

  if (!isArray(response.data)) {
    throw new Error('Unexpected response: Response data is not an array');
  }

  return response.data.map((target) => targetFromResponse(target));
};

export const deleteTarget = async (targetID: number): Promise<void> =>
  api.delete(targetBaseUrl + targetID.toString());
