import { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { isEmpty } from 'lodash';
import { Stock } from '../../interfaces/Stock';
import { getTargetToCreateTemplate } from '../../utils/Target';
import { createTarget } from '../../services/Target';
import Waiting from '../Waiting';
import { sharedColors, sharedStyles } from '../../utils/Style';
import StockAutocomplete from '../Stock/StockAutocomplete';
import NumberInput from '../NumberInput';

interface CreateTargetDialogProps {
  open: boolean;
  stocks: Stock[];
  onClose: () => void;
  onCreate: () => void;
}

const CreateTargetDialog = (props: CreateTargetDialogProps) => {
  const [loading, setLoading] = useState(false);
  const [currentState, setCurrentState] = useState(
    getTargetToCreateTemplate(props.stocks),
  );

  useEffect(() => {
    setCurrentState(getTargetToCreateTemplate(props.stocks));
  }, [props.open, props.stocks]);

  const handleSelectStock = (newStock: Stock | null) => {
    setCurrentState({
      ...currentState,
      symbol: newStock?.symbol ?? '',
      value: newStock?.close ?? currentState.value,
    });
  };

  const handleConfirm = () => {
    setLoading(true);
    createTarget(currentState)
      .then(() => {
        toast.success('Hedef başarıyla oluşturuldu');
        props.onCreate();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const selectedStock =
    props.stocks.find((stock) => stock.symbol === currentState.symbol) ?? null;

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <Waiting open={loading} />
      <DialogTitle sx={{ ...sharedStyles.h6, color: sharedColors.gray6 }}>
        Yeni Hedef
      </DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box component='div' sx={{ mt: 1.5 }}>
          <StockAutocomplete
            label='Sembol'
            width={150}
            options={props.stocks}
            value={selectedStock}
            onChange={handleSelectStock}
          />
        </Box>
        <Box
          component='div'
          sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, mt: 1.5 }}
        >
          <Button
            color='primary'
            variant='outlined'
            onClick={() =>
              setCurrentState({
                ...currentState,
                isGreater: !currentState.isGreater,
              })
            }
            sx={{ mr: 1.5 }}
          >
            {currentState.isGreater ? '>' : '<'}
          </Button>
          <NumberInput
            label='Hedef Değer'
            value={currentState.value}
            onChange={(newValue) =>
              setCurrentState({ ...currentState, value: newValue })
            }
            acceptFloat
            precision={2}
            error={currentState.value <= 0}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 2.5, pb: 1.5 }}>
        <Button
          variant='contained'
          color='inherit'
          onClick={props.onClose}
          sx={sharedStyles.buttonText}
        >
          İptal
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={isEmpty(currentState.symbol) || currentState.value <= 0}
          onClick={handleConfirm}
          sx={sharedStyles.buttonText}
        >
          Onayla
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateTargetDialog;
