import { HistoryPeriod } from '../interfaces/HistoryPeriod';

export const getTextFromHistoryPeriod = (
  historyPeriod: HistoryPeriod,
): string => {
  switch (historyPeriod) {
    case HistoryPeriod.WEEK:
      return '1 Hafta';
    case HistoryPeriod.MONTH:
      return '1 Ay';
    case HistoryPeriod.THREE_MONTHS:
      return '3 Ay';
    case HistoryPeriod.SIX_MONTHS:
      return '6 Ay';
    default:
      return 'Tümü';
  }
};

export const getWorkdayCountFromPeriod = (
  historyPeriod: HistoryPeriod,
): number => {
  switch (historyPeriod) {
    case HistoryPeriod.WEEK:
      return 5;
    case HistoryPeriod.MONTH:
      return 22;
    case HistoryPeriod.THREE_MONTHS:
      return 70;
    case HistoryPeriod.SIX_MONTHS:
      return 140;
    default:
      return 99999999;
  }
};
