import React, { useEffect, useState } from 'react';
import { Box, Button, Paper, TextField } from '@mui/material';
import { useSetRecoilState } from 'recoil';
import { toast } from 'material-react-toastify';
import { useNavigate } from 'react-router-dom';
import Waiting from '../Waiting';
import { sharedStyles } from '../../utils/Style';
import { selectedPageAtom } from '../../atoms/Page';
import { login } from '../../services/User';
import { isTokenValid, storeToken } from '../../utils/Auth';
import { PageType } from '../../interfaces/Page';

const LoginPage = () => {
  const navigate = useNavigate();

  const setSelectedPage = useSetRecoilState(selectedPageAtom);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectedPage(PageType.LOGIN);
    if (isTokenValid()) {
      navigate('/');
    }
  }, []);

  const handleChangeUsername = (e: any) => {
    setUsername(e.target.value as string);
  };

  const handleChangePasswordValue = (e: any) => {
    setPassword(e.target.value as string);
  };

  const handleLogin = () => {
    setLoading(true);
    login(username, password)
      .then((result) => {
        if (result.authToken) {
          storeToken(result.authToken);
          navigate('/');
        }
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  const handleTextFieldKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      handleLogin();
    }
  };

  return (
    <Box
      component='div'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <Waiting open={loading} />
      <Paper sx={{ p: 2 }}>
        <Box component='div' sx={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            size='small'
            label='Kullanıcı Adı'
            value={username}
            sx={{ m: 1.5 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChangeUsername}
            onKeyDown={handleTextFieldKeyDown}
          />
          <TextField
            size='small'
            label='Şifre'
            value={password}
            sx={{ marginBottom: 1.5, marginLeft: 1.5, marginRight: 1.5 }}
            type='password'
            InputLabelProps={{
              shrink: true,
            }}
            onChange={handleChangePasswordValue}
            onKeyDown={handleTextFieldKeyDown}
          />
          <Box
            component='div'
            display='flex'
            flexDirection='row'
            flexGrow={1}
            sx={{ mt: 1.5 }}
          >
            <Box component='div' flexGrow={1} />
            <Button
              color='primary'
              size='small'
              onClick={handleLogin}
              sx={sharedStyles.buttonText}
            >
              Giriş
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default LoginPage;
