export enum QueryType {
  CONSTANTLY_FALLING = 'CONSTANTLY_FALLING',
  CONSTANTLY_RISING = 'CONSTANTLY_RISING',
  PERCENT_FALLING = 'PERCENT_FALLING',
  PERCENT_RISING = 'PERCENT_RISING',
  MINIMUM_VALUES = 'MINIMUM_VALUES',
  RSI = 'RSI',
}

export type QueryParams = {
  [key: string]: number;
};

export interface QueryFormProps {
  queryType: QueryType;
  queryParams: QueryParams;
  onChange: (newParams: QueryParams) => void;
}
