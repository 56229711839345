import { isArray } from 'lodash';
import { Stock, stockFromResponse } from '../interfaces/Stock';
import api from './Api';

const stocksBaseUrl = '/stock/';

export const getStocks = async (): Promise<Stock[]> => {
  const response = await api.get(stocksBaseUrl);

  if (!isArray(response.data)) {
    throw new Error('Unexpected response: Response data is not an array');
  }

  return response.data.map((stock) => stockFromResponse(stock));
};

export const getStockHistory = async (symbol: string): Promise<Stock[]> => {
  const response = await api.get(stocksBaseUrl + symbol);

  if (!isArray(response.data)) {
    throw new Error('Unexpected response: Response data is not an array');
  }

  return response.data.map((stock) => stockFromResponse(stock));
};
