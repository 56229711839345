import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { toast } from 'material-react-toastify';
import { Pagination } from '../../interfaces/Pagination';
import { Target } from '../../interfaces/Target';
import { sharedColors, sharedStyles } from '../../utils/Style';
import { formatLocalTime } from '../../utils/Date';
import { Stock } from '../../interfaces/Stock';
import CreateTargetDialog from './CreateTargetDialog';
import ConfirmDialog from '../ConfirmDialog';
import { deleteTarget } from '../../services/Target';
import Waiting from '../Waiting';
import StockHistoryDialog from '../Stock/StockHistoryDialog';

interface TargetsTableProps {
  targets: Target[];
  stocks: Stock[];
  onChange: () => void;
}

const TargetsTable = (props: TargetsTableProps) => {
  const [pagination, setPagination] = useState<Pagination>({
    pageNumber: 0,
    pageSize: 15,
  });
  const [loading, setLoading] = useState(false);
  const [searchedValue, setSearchedValue] = useState('');
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [targetToDelete, setTargetToDelete] = useState<Target | undefined>(
    undefined,
  );
  const [clickedSymbol, setClickedSymbol] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    setPagination({
      ...pagination,
      pageNumber: 0,
    });
  }, [searchedValue]);

  const handleSearch = (e: any) => {
    setSearchedValue(e.target.value ?? '');
  };

  const handleDelete = () => {
    if (!targetToDelete) {
      return;
    }

    const targetID = targetToDelete.targetID;
    setTargetToDelete(undefined);
    setLoading(true);
    deleteTarget(targetID)
      .then(() => {
        toast.success('Hedef başarıyla silindi');
        props.onChange();
      })
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  };

  let filteredRows = props.targets.filter((target) =>
    target.symbol.toUpperCase().includes(searchedValue.toUpperCase()),
  );

  filteredRows = filteredRows.sort((t1, t2) =>
    t1.symbol.localeCompare(t2.symbol),
  );

  const paginatedRows = filteredRows.slice(
    pagination.pageNumber * pagination.pageSize,
    (pagination.pageNumber + 1) * pagination.pageSize,
  );

  return (
    <Box component='div' sx={sharedStyles.outerTableContainer}>
      <Waiting open={loading} />
      <StockHistoryDialog
        symbol={clickedSymbol}
        onClose={() => setClickedSymbol(undefined)}
      />
      {targetToDelete && (
        <ConfirmDialog
          content={`Gerçekten ${targetToDelete.symbol} için belirlenen hedefi silmek istiyor musunuz?`}
          open
          onClose={() => setTargetToDelete(undefined)}
          onConfirm={handleDelete}
          color='error'
        />
      )}
      <CreateTargetDialog
        open={showCreateDialog}
        stocks={props.stocks}
        onClose={() => setShowCreateDialog(false)}
        onCreate={() => {
          setShowCreateDialog(false);
          props.onChange();
        }}
      />
      <Typography
        sx={{
          ...sharedStyles.h5,
          color: sharedColors.black,
          fontWeight: 450,
        }}
      >
        Mevcut Hedefler
      </Typography>
      <Box
        component='div'
        sx={{ display: 'flex', flexGrow: 1, flexDirection: 'row', mt: 1.5 }}
      >
        <TextField
          id='search-text-field'
          size='small'
          label='Ara'
          variant='outlined'
          value={searchedValue}
          onChange={handleSearch}
          sx={{ width: 230, backgroundColor: sharedColors.white, mr: 1.5 }}
        />
        <Box component='div' sx={{ flexGrow: 1 }} />
        <Button
          variant='contained'
          color='primary'
          onClick={() => setShowCreateDialog(true)}
          sx={{ ...sharedStyles.buttonText, mt: 'auto', mb: 'auto' }}
        >
          + Yeni Hedef
        </Button>
      </Box>
      <TableContainer component={Paper} sx={sharedStyles.tableContainer}>
        <Table size='small'>
          <TableHead sx={{ backgroundColor: sharedColors.gray2 }}>
            <TableRow>
              <TableCell>
                <Typography sx={sharedStyles.columnLabel}>Sembol</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={sharedStyles.columnLabel}>Hedef</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={sharedStyles.columnLabel}>
                  Güncel Değer
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={sharedStyles.columnLabel}>Durum</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={sharedStyles.columnLabel}>
                  Hedef Belirleme Tarihi
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={sharedStyles.columnLabel}>
                  Hisse Güncellenme Tarihi
                </Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography sx={sharedStyles.columnLabel}>İşlem</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.map((target) => (
              <TableRow key={target.targetID} sx={sharedStyles.editableRow}>
                <TableCell onClick={() => setClickedSymbol(target.symbol)}>
                  <Typography sx={sharedStyles.tableStringField}>
                    {target.symbol}
                  </Typography>
                </TableCell>
                <TableCell onClick={() => setClickedSymbol(target.symbol)}>
                  <Typography sx={sharedStyles.tableStringField}>
                    {(target.isGreater ? '> ' : '< ') +
                      target.targetValue.toFixed(2)}
                  </Typography>
                </TableCell>
                <TableCell onClick={() => setClickedSymbol(target.symbol)}>
                  <Typography sx={sharedStyles.tableStringField}>
                    {target.currentValue.toFixed(2)}
                  </Typography>
                </TableCell>
                <TableCell onClick={() => setClickedSymbol(target.symbol)}>
                  <Typography sx={sharedStyles.tableStringField}>
                    {target.isSatisfied ? 'Gerçekleşti' : 'Bekliyor'}
                  </Typography>
                </TableCell>
                <TableCell onClick={() => setClickedSymbol(target.symbol)}>
                  <Typography sx={sharedStyles.tableStringField}>
                    {target.targetLastUpdatedAt
                      ? formatLocalTime(target.targetLastUpdatedAt)
                      : '-'}
                  </Typography>
                </TableCell>
                <TableCell onClick={() => setClickedSymbol(target.symbol)}>
                  <Typography sx={sharedStyles.tableStringField}>
                    {target.stockLastUpdatedAt
                      ? formatLocalTime(target.stockLastUpdatedAt)
                      : '-'}
                  </Typography>
                </TableCell>
                <TableCell align='center'>
                  <Button
                    onClick={() => setTargetToDelete(target)}
                    color='secondary'
                    sx={{
                      ...sharedStyles.buttonText,
                      p: 0,
                      minWidth: 0,
                    }}
                  >
                    Sil
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          count={filteredRows.length}
          page={pagination.pageNumber}
          onPageChange={(_, newPage) =>
            setPagination({ ...pagination, pageNumber: newPage })
          }
          rowsPerPage={pagination.pageSize}
          onRowsPerPageChange={(e) =>
            setPagination({
              pageNumber: 0,
              pageSize: parseInt(e.target.value, 10),
            })
          }
          rowsPerPageOptions={[5, 10, 15, 25, 50]}
          component='div'
          labelRowsPerPage='Sayfa başına satır:'
        />
      </TableContainer>
    </Box>
  );
};

export default TargetsTable;
