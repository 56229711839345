import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { sharedStyles } from '../utils/Style';

interface ConfirmDialogProps {
  title?: string;
  content: string;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  color: 'primary' | 'error';
  children?: JSX.Element[] | JSX.Element;
}

const ConfirmDialog = (props: ConfirmDialogProps) => (
  <Dialog open={props.open} onClose={props.onClose}>
    <DialogTitle>{props.title ?? 'Emin Misiniz?'}</DialogTitle>
    <DialogContent>
      <DialogContentText>{props.content}</DialogContentText>
      {props.children ?? []}
    </DialogContent>
    <DialogActions>
      <Button
        variant='contained'
        color='inherit'
        onClick={props.onClose}
        sx={sharedStyles.buttonText}
      >
        Vazgeç
      </Button>
      <Button
        variant='contained'
        onClick={props.onConfirm}
        autoFocus
        color={props.color}
        sx={sharedStyles.buttonText}
      >
        Onayla
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmDialog;
