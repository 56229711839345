import { Autocomplete, TextField } from '@mui/material';
import { Stock } from '../../interfaces/Stock';

interface StockAutocompleteProps {
  label?: string;
  width?: number;
  backgroundColor?: string;
  disabled?: boolean;
  options: Stock[];
  value: Stock | null;
  onChange: (newStock: Stock | null) => void;
}

const StockAutocomplete = (props: StockAutocompleteProps) => (
  <Autocomplete
    renderInput={(params) => (
      <TextField
        {...params}
        label={props.label}
        variant='outlined'
        size='small'
        sx={{
          width: props.width ?? 250,
          backgroundColor: props.backgroundColor,
        }}
        disabled={props.disabled}
      />
    )}
    options={props.options}
    getOptionLabel={(stock) => stock.symbol}
    value={props.value}
    onChange={(_, newStock) => props.onChange(newStock)}
    disabled={props.disabled}
  />
);

export default StockAutocomplete;
