import { Box, Button } from '@mui/material';
import { QueryFormProps } from '../../../interfaces/Query';
import { sharedColors } from '../../../utils/Style';
import NumberInput from '../../NumberInput';

const RsiIndicatorForm = (props: QueryFormProps) => {
  return (
    <Box
      component='div'
      sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, mt: 1.5 }}
    >
      <Box
        component='div'
        sx={{ mr: 1.5, width: 150, backgroundColor: sharedColors.white }}
      >
        <NumberInput
          label='Gün'
          value={props.queryParams.days}
          onChange={(newDays) =>
            props.onChange({ ...props.queryParams, days: newDays })
          }
          error={!props.queryParams.days || props.queryParams.days < 0}
        />
      </Box>
      <Button
        color='primary'
        variant='outlined'
        onClick={() =>
          props.onChange({
            ...props.queryParams,
            greater: -props.queryParams.greater,
          })
        }
        sx={{ mr: 1.5 }}
      >
        {props.queryParams.greater > 0 ? '>' : '<'}
      </Button>
      <Box
        component='div'
        sx={{ mr: 1.5, width: 150, backgroundColor: sharedColors.white }}
      >
        <NumberInput
          label='Hedef RSI'
          value={props.queryParams.target}
          onChange={(newTarget) =>
            props.onChange({ ...props.queryParams, target: newTarget })
          }
          error={!props.queryParams.target || props.queryParams.target < 0}
        />
      </Box>
    </Box>
  );
};

export default RsiIndicatorForm;
