export interface Stock {
  symbol: string;
  day: string;
  close: number;
  high: number;
  low: number;
  metadata: { [key: string]: string };
  lastUpdatedAt?: Date;
}

export const stockFromResponse = (responseData: any): Stock => ({
  symbol: responseData.symbol,
  day: responseData.day,
  close: responseData.close,
  high: responseData.high,
  low: responseData.low,
  metadata: responseData.metadata ?? {},
  lastUpdatedAt: responseData.last_updated_at
    ? new Date(responseData.last_updated_at)
    : undefined,
});
