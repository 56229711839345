import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { toast } from 'material-react-toastify';
import { Box } from '@mui/material';
import { Stock } from '../../interfaces/Stock';
import { selectedPageAtom } from '../../atoms/Page';
import { PageType } from '../../interfaces/Page';
import { getStocks } from '../../services/Stock';
import Waiting from '../Waiting';
import { sharedStyles } from '../../utils/Style';
import StocksTable from './StocksTable';

const StocksPage = () => {
  const setSelectedPage = useSetRecoilState(selectedPageAtom);

  const [loading, setLoading] = useState(false);
  const [stocks, setStocks] = useState<Stock[]>([]);

  useEffect(() => {
    setSelectedPage(PageType.STOCKS);

    setLoading(true);
    getStocks()
      .then((fetchedStocks) => setStocks(fetchedStocks))
      .catch((err) => toast.error(err.message))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Box component='div'>
      <Waiting open={loading} />
      <Box component='div' sx={sharedStyles.pageBody}>
        <StocksTable stocks={stocks} />
      </Box>
    </Box>
  );
};

export default StocksPage;
