import React, { useEffect } from 'react';
import './App.css';
import { Box } from '@mui/material';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { toast, ToastContainer } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import { isTokenValid } from './utils/Auth';
import { selectedPageAtom } from './atoms/Page';
import { appBarHeightAtom } from './atoms/AppHeader';
import { pages } from './variables/Page';
import AppHeader from './components/AppHeader';
import LoginPage from './components/Login/LoginPage';

const App = () => {
  const navigate = useNavigate();

  const appBarHeight = useRecoilValue(appBarHeightAtom);
  const selectedPage = useRecoilValue(selectedPageAtom);

  useEffect(() => {
    if (!isTokenValid()) {
      navigate('/login');
    }
  }, [selectedPage]);

  const handleClickToaster = (e: any) => {
    navigator.clipboard
      .writeText(e.target.outerText)
      .then(() => toast.info('Panoya Kopyalandı'));
  };

  const loggedIn = isTokenValid();

  return (
    <Box component='div'>
      <ToastContainer
        position='top-center'
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop
        onClick={handleClickToaster}
        rtl={false}
        draggable
        pauseOnHover
      />
      {loggedIn && <AppHeader />}
      <Box component='div' sx={{ height: loggedIn ? appBarHeight : 0 }} />
      <Routes>
        <Route path='/login/*' element={<LoginPage />} />
        {loggedIn && [
          <Route
            path='/'
            element={<Navigate to={`${pages[0].path}`} replace />}
          />,
          <Route
            path=''
            element={<Navigate to={`${pages[0].path}`} replace />}
          />,
          pages.map((page) => (
            <Route path={`${page.path}/*`} element={page.component} />
          )),
        ]}
      </Routes>
    </Box>
  );
};

export default App;
