import { QueryParams, QueryType } from '../interfaces/Query';

export const getQueryTypeName = (queryType: QueryType): string => {
  switch (queryType) {
    case QueryType.CONSTANTLY_FALLING:
      return 'Üst üste düşen hisseler';
    case QueryType.CONSTANTLY_RISING:
      return 'Üst üste yükselen hisseler';
    case QueryType.PERCENT_FALLING:
      return 'Periyot içerisinde yüzdelik düşen hisseler';
    case QueryType.PERCENT_RISING:
      return 'Periyot içerisinde yüzdelik yükselen hisseler';
    case QueryType.MINIMUM_VALUES:
      return 'Periyodik minimum değerler';
    case QueryType.RSI:
      return 'RSI indikatörü';
    default:
      return 'Diğer';
  }
};

export const getDefaultQueryParamsByType = (
  queryType: QueryType,
): QueryParams => {
  switch (queryType) {
    case QueryType.CONSTANTLY_FALLING:
    case QueryType.CONSTANTLY_RISING:
      return { days: 5 };
    case QueryType.PERCENT_FALLING:
    case QueryType.PERCENT_RISING:
      return { days: 10, percentage: 30 };
    case QueryType.RSI:
      return { days: 14, target: 30, greater: -1 };
    default:
      return {};
  }
};
