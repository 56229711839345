import axios from 'axios';
import { getStoredToken, isTokenValid, removeToken } from '../utils/Auth';

const api = axios.create({
  baseURL: '/api',
});

api.interceptors.request.use((request) => {
  const token = getStoredToken();
  if (token) {
    if (isTokenValid()) {
      request.headers.set('Authorization', `Bearer ${token}`);
    } else {
      removeToken();
    }
  }

  return request;
});

api.interceptors.response.use(
  (response) => response,
  async (err) => {
    if (err?.response?.data?.message) {
      throw new Error(err.response.data.message);
    }
    if (err?.response?.data?.messages) {
      throw new Error('Cannot parse error messages.');
    }
    throw new Error('Unknown API error.');
  },
);

export default api;
